declare const SERVICE_WORKER_MANIFEST_ENTRIES;
self.__WB_DISABLE_DEV_LOGS = true;
import {precache} from 'workbox-precaching';
import { cacheNames } from 'workbox-core'
import {registerRoute} from 'workbox-routing';
import {NetworkFirst} from 'workbox-strategies';

try {
	precache(SERVICE_WORKER_MANIFEST_ENTRIES)
} catch (error) {
	
}
registerRoute(
 	(url) => url.sameOrigin,
  new NetworkFirst({cacheName: cacheNames.precache})
);